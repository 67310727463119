import moment from 'moment';
import 'slick-carousel';
import 'magnific-popup';

jQuery( document ).ready(function($) {
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/3 Doors Down/events?app_id=45PRESS_3doorsdown',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            console.log(data);
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event event-row" data-bit-id="' + event.id + '">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMMM DD YYYY @ hh:mm A' ) + '</div>';
                    html += '<div class="event-venue">' + event.venue.name + '</div>';
                    html += '<div class="event-location">' + event.venue.city + ', ' + event.venue.region + '</div>';
                    html += '<div class="event-links">'; 
                    if (event.offers.length){
                        event.offers.forEach(function(offer){
                            html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>'; 
                        });
                    } 
                    html += '</div>'
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( '<p class="soon">No upcoming events</p>' );
            }
        }
    });

    
    $('.hero-carousel').slick({
        prevArrow: '<button type="button" class="slick-prev"><i aria-label="prev" class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i aria-label="next" class="fas fa-chevron-right"></i></button>',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.video-carousel').slick({
        prevArrow: '<button type="button" class="slick-prev"><i aria-label="prev" class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i aria-label="next" class="fas fa-chevron-right"></i></button>',
        infinite: true,
        slidesToShow: 3,
        initialSlide: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                initialSlide: 0,
              }
            }
        ]
    });

    $('#main').magnificPopup({
        delegate: '.mfp-iframe',
        type: 'iframe'
    })
});